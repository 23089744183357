<template>
  <div class="advantage">
    <div class="innovation-pilot">
      <div class="innovation-pilot-content">
        <p class="innovation-pilot-title">
          中新宽维与临港新片区共建跨境直播基地
        </p>
        <p class="innovation-pilot-intro">
          牢牢把握中国在直播电商行业处于全球领先的地位，积极布局和拓展海外市场，抓住新片区先行先试的政策优势、双方资源优势和技术研发优势，打造国家级跨境直播数字贸易基地，吸引国内直播贸易企业入驻、聚集，形成新的数字贸易经济产业聚集地。
        </p>
      </div>
    </div>

    <div class="dedicated-channel">
      <div class="dedicated-channel-content">
        <div class="dedicated-channel-content-text">
          <p class="dedicated-channel-content-title">
            建设国际互联数据专用通道
          </p>
          <p class="dedicated-channel-intro">探索“四个一”推进机制</p>
          <p class="dedicated-channel-intro">
            探索建立一个数据跨境流通统筹协调机制
          </p>
          <p class="dedicated-channel-intro">
            建设国际互联网数据专用通道、功能型数据中心等一批新型基础设施
          </p>
          <p class="dedicated-channel-intro">
            搭建一个数据跨境流通公共服务平台
          </p>
          <p class="dedicated-channel-intro">
            协助国家和市相关主管部门推进一批企业数据跨境流动安全评估试点
          </p>
        </div>
        <img src="@/assets/images/advantage/dedicated-channel.png" />
      </div>
    </div>

    <div class="innovation-heights">
      <div class="heights-content">
        <img src="@/assets/images/advantage/innovation-heights.png" />
        <div class="heights-content-text">
          <p class="heights-content-title">数字贸易制度创新高地</p>
          <p class="heights-intro1">
            上海市政府发布《上海市全面深化服务贸易创新发展试点实施方案》，发挥临港新片区作为特殊经济功能区先行先试的优势，率先开展资金、人才和数据跨境流动便利化试点，加快打造服务贸易制度创新高地。
          </p>
          <p class="heights-intro2">
            目前，上海临港新片区已出台了
            《中国（上海）自由贸易试验区临港新片区数字化发展“十四五”规划》，提出设“十百千”目标以推动数据跨境安全有序流动。
          </p>
          <p class="heights-intro2">
            推动10个标志性企业率先实现跨境数据流通试点
          </p>
          <p class="heights-intro2">汇聚100家数据及智能领域的头部企业</p>
          <p class="heights-intro2">
            带动引领100个跨境数据交互的示范应用项目实施
          </p>
          <p class="heights-intro2">数字产业规模突破1000亿</p>
        </div>
      </div>
    </div>

    <div class="data-security">
      <p class="data-security-title">国际数据港数据安全有序流动</p>
      <div class="data-security-content">
        <p>
          国际互联网数据专用通道投入使用，成立国家（上海）新型互联网交换中心。建成临港新片区数据跨境流动公共服务管理系统。实现首批企业通过数据跨境流动安全评估试点。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.advantage {
  flex: 1;
  margin-top: -24px;
  margin-bottom: -24px;

  .innovation-pilot {
    width: 100%;
    height: 440px;
    background: url("../../assets/images/download-bg2.png") center no-repeat;
    background-size: cover;
    padding-top: 117px;
    .innovation-pilot-content {
      width: 1440px;
      margin: 0 auto;
      p {
        color: #39425c;
        &.innovation-pilot-title {
          font-size: 44px;
          font-weight: bold;
          margin-bottom: 38px;
        }
        &.innovation-pilot-intro {
          font-size: 18px;
          line-height: 36px;
          width: 630px;
          text-indent: 2em;
          text-align: justify;
        }
      }
    }
  }

  .dedicated-channel {
    width: 100%;
    height: 770px;
    background-color: #f2f6ff;
    padding-top: 250px;
    .dedicated-channel-content {
      width: 1440px;
      display: flex;
      height: 400px;
      margin: 0 auto;
      .dedicated-channel-content-text {
        flex: 1;
        .dedicated-channel-content-title {
          font-size: 44px;
          color: #39425c;
          margin-top: 85px;
          margin-bottom: 50px;
          font-weight: bold;
        }
        .dedicated-channel-intro {
          margin: 0;
          color: #535d7a;
          font-size: 18px;
          line-height: 36px;
        }
      }
      img {
        width: 710px;
        height: 400px;
        object-fit: cover;
      }
    }
  }

  .innovation-heights {
    width: 100%;
    height: 770px;
    background-color: #ffffff;
    padding-top: 170px;
    .heights-content {
      width: 1440px;
      height: 400px;
      margin: 0 auto;
      display: flex;
      img {
        width: 710px;
        height: 400px;
        object-fit: cover;
      }
      .heights-content-text {
        flex: 1;
        margin-left: 45px;
        .heights-content-title {
          font-size: 44px;
          color: #39425c;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .heights-intro1 {
          font-size: 20px;
          color: #39425c;
          text-indent: 2em;
          text-align: justify;
          line-height: 36px;
          margin-bottom: 20px;
        }
        .heights-intro2 {
          font-size: 16px;
          color: #535d7a;
          text-indent: 2em;
          line-height: 36px;
          margin-bottom: 0;
        }
      }
    }
  }

  .data-security {
    width: 100%;
    height: 770px;
    padding-top: 118px;
    background: url("../../assets/images/advantage/bg.jpg") center no-repeat;
    background-size: cover;
    .data-security-title {
      text-align: center;
      color: #ffffff;
      font-size: 44px;
      font-weight: bold;
      margin-bottom: 50px;
    }
    .data-security-content {
      width: 1440px;
      height: 300px;
      border: 1px solid #ffffff;
      border-radius: 10px;
      margin: 0 auto;
      background-color: rgba(255, 255, 255, 0.1);
      padding: 114px 75px 0 75px;
      p {
        font-size: 24px;
        color: #ffffff;
        font-weight: bold;
        text-indent: 2em;
        line-height: 36px;
      }
    }
  }
}
</style>
